<template>
  <v-app>
    <div class="card">
      <div class="card-header">
        <div class="card-title pt-3 px-3 d-flex justify-content-between">
          <div class="breadcrumb-left">
            <h4>  Roles & permissionss</h4>
            <div class="breadcrumb-sub-header">
              <router-link to="/dashboard">Dashboard </router-link>\   Roles & permissions
            </div>
          </div>
          <div class="breadcrumb-right">
            <div class="card-toolbar">
              <v-btn :loading="loading"  @click="rebootPermission()" class="mt-4 btn btn-primary" style="color: #fff">
                <v-icon small elevation="2" outlined>fas fa-sync</v-icon>&nbsp;
                Reboot permission
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body ">
        <div>
          <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

          <v-skeleton-loader v-if="loading" type="table-row-divider@25">
          </v-skeleton-loader>
          <table class="table" v-if="!loading">
            <thead>
            <tr class="px-3">
              <th>#</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(role, index) of roles" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <span v-if="role.name && checkIsAccessible('permission','list')">
                  <a href="#"  @click="managePermission(role.id)">
                    {{ role.name }} </a>
                </span>
                <span v-else>-</span>
              </td>
              <td>
                <!-- <span class="cursor-pointer ml-2" @click="deleteUser(slider.id)">
                <i class="fas fa-trash"></i>
                </span>-->
                <b-dropdown
                    size="sm"
                    variant="link"
                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                    no-caret
                    right
                    no-flip
                >
                  <template v-slot:button-content>
                    <slot>
                      <span>
                        <i class="flaticon-more-1"></i>
                      </span></slot>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover ">

                    <b-dropdown-text tag="div" class="navi-item">
                      <a href="#" class="navi-link" @click="managePermission(role.id)">
                        <span class="navi-icon">
                          <i class="fas fa-cog "></i>
                        </span>
                        <span class="navi-text">  Manage permission</span>
                      </a>
                    </b-dropdown-text>


                  </div>
                  <!--end::Navigation-->
                </b-dropdown>

              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import RoleService from "@/core/services/user/role/RoleService";
import RoleCustomPermission from "@/core/services/user/role/RoleCustomPermission";

const userService = new UserService();
const roleService = new RoleService();
const customPermission = new RoleCustomPermission();

export default {
  name: "RoleUser",
  components: {},
  data() {
    return {
      roles: [],
      user: {},
      total: null,
      perPage: null,
      loading: false
    };
  },
  computed: {
    user_id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    userRoles() {
      this.loading = true;
      userService.userRoles(this.user_id).then(response => {
        this.user_roles = response.data;
        this.selected_roles = [];
        this.user_roles.forEach(element => {
          this.selected_roles.push(element);
        });
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    getUser() {
      userService.show(this.user_id).then(response => {
        this.user = response.data.user;
      });
    },
    getRoles() {
      roleService.all().then(response => {
        let roles = response.data.data;
        roles.forEach(role => {
          if(role.name != 'admin' && role.name != 'student' && role.name != 'teacher' && role.name != 'driver' && role.name != 'customer') {
            this.roles.push(role)
          }
        })
      });
    },

    managePermission(id) {
      this.$router.push({"name": "role-permission", params: {id: id}})
    },
    deleteUserRole(role) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            userService
                .removeRole(this.user_id, {roles: [role]})
                .then(response => {
                  this.$snotify.success("Information deleted");

                  this.userRoles();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },
    assignRole() {
      userService
          .assignRole(this.user_id, {roles: this.selected_roles})
          .then(response => {
            this.user_roles = [];
            this.hideModal();
            this.userRoles();
          });
    },
    rebootPermission() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.loading = true
            customPermission.rebootPermission().then(response => {
              this.loading = false;
              this.$snotify.success("Information updated")
            }).catch(error => {
              this.loading = true;
            })
          }
        }
      });
    }
  }
};
</script>
